"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.myDAPPwalletConfig = exports.myDAPPwallet = exports.myDAPPwalletConnector = exports.myDAPPwalletProvider = void 0;
var provider_1 = require("./provider");
Object.defineProperty(exports, "myDAPPwalletProvider", { enumerable: true, get: function () { return provider_1.myDAPPwalletProvider; } });
var connector_1 = require("./connector");
Object.defineProperty(exports, "myDAPPwalletConnector", { enumerable: true, get: function () { return connector_1.myDAPPwalletConnector; } });
var wallet_1 = require("./wallet");
Object.defineProperty(exports, "myDAPPwallet", { enumerable: true, get: function () { return wallet_1.myDAPPwallet; } });
var config_1 = require("./config");
Object.defineProperty(exports, "myDAPPwalletConfig", { enumerable: true, get: function () { return config_1.myDAPPwalletConfig; } });
