"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.myDAPPwallet = void 0;
const wallets_1 = require("@thirdweb-dev/wallets");
const connector_1 = require("../connector");
const constants_1 = require("../constants");
class myDAPPwallet extends wallets_1.AbstractClientWallet {
    constructor(options) {
        super(constants_1.walletId, options);
        this.id = constants_1.walletId;
        this.apiKey = options.apiKey;
        switch (options.chain.slug) {
            case "ethereum":
                this.url = "https://unity888.mydappwallet.com";
                break;
        }
    }
    async getConnector() {
        if (!this.connector) {
            this.connector = new connector_1.myDAPPwalletConnector({ "apiKey": this.apiKey });
        }
        return this.connector;
    }
}
exports.myDAPPwallet = myDAPPwallet;
