"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.myDAPPwalletConfig = void 0;
const wallet_1 = require("../wallet");
const constants_1 = require("../constants");
function myDAPPwalletConfig(options) {
    return {
        id: constants_1.walletId,
        meta: {
            name: "myDAPPwallet",
            iconURL: "http://wallet.mydappwallet.com/myDAPPWallet-connect.svg", // or ipfs://...
        },
        // create and return wallet instance
        create(walletOptions) {
            return new wallet_1.myDAPPwallet({ ...walletOptions, ...options });
        }
        /*
        // optional - render a UI for connecting your wallet
        connectUI(props) {
          return <MyWalletConnectionUI {...props} />;
        },
    
        // optional - override the default UI for selecting your wallet in the wallet selector screen
        selectUI(props) {
          return <MyWalletSelectionUI {...props} />
        }
        */
    };
}
exports.myDAPPwalletConfig = myDAPPwalletConfig;
;
