"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.myDAPPwalletConnector = void 0;
const wallets_1 = require("@thirdweb-dev/wallets");
const provider_1 = require("../provider");
class myDAPPwalletConnector extends wallets_1.Connector {
    constructor(params) {
        super();
        this.connect = this.connect.bind(this);
        this.getSigner = this.getSigner.bind(this);
        this.provider = new provider_1.myDAPPwalletProvider("https://unity888.mydappwallet.com", "a2c5d504-0f33-4d69-b15b-baf3486c4882");
    }
    async connect(args) {
        return this.provider.connect();
    }
    async disconnect() {
        if (!this.provider) {
            throw new Error("No provider available");
        }
        return this.provider.disconnect();
    }
    async getAddress() {
        const signer = await this.getSigner();
        return await signer.getAddress();
    }
    async getChainId() {
        return (await this.getSigner()).getChainId();
    }
    async getProvider() {
        const provider = (await this.getSigner()).provider;
        if (!provider) {
            throw new Error("No provider available");
        }
        return provider;
    }
    async getSigner() {
        if (!this.provider) {
            throw new Error("No provider available");
        }
        return this.provider.getSigner();
    }
    async isConnected() {
        try {
            const account = await this.getAddress();
            return !!account;
        }
        catch (e) {
            return false;
        }
    }
    onDisconnect() {
        this.emit("disconnect");
    }
    switchChain() {
        throw new Error("Safe connector does not support switching chains");
    }
    updateChains() { }
    async setupListeners() { }
}
exports.myDAPPwalletConnector = myDAPPwalletConnector;
